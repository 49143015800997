<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="productions" v-infinite-scroll="load" style="overflow:auto;">
			<el-row class="productions-data">
				<el-row v-if="notData">
					<div class="productions-bigbox" v-for="(item,id) in listSix" :key="id" @click="todetails(item)">
						<div class="productions-imgbox">
							<img :src="proNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="productions-img"></img>
						</div> 
						<div class="productions-databox">
							<el-row>
								<el-col :span="1">
									<el-image src="/index/vertical.png" class="vertical-img"></el-image>
								</el-col>
								<el-col :span="20" :offset="1">
									<el-row class="productions-title">
										{{item.title}}
									</el-row>
									<el-row class="productions-message" v-html="item.bodyContent">
										{{item.bodyContent}}
									</el-row>
								</el-col>
								<el-col :span="1" :offset="1">
									<el-row v-if="id+1<10" class="productions-number">
										0{{id+1}}
									</el-row>
									<el-row v-else class="productions-number">
										{{id+1}}
									</el-row>
								</el-col>
							</el-row>
						</div> 
					</div> 
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				count: 0,
				
				// 头部导航标题
				headTitle:'PRODUCTIONS',
				// 头部导航图片
				headImg:'/index/production.png',
				// 导航值
				navigationvalue:false,
				
				list:[],
				
				notData:true,
				
				listSix:[],
				
				proNotLoadImage: "/Mimage/664_290.jpg", //产品图片未加载出来
			}
		},
		components:
		{
			moveHeadLogo,
			moveNavigation,
			moveBottomNavigation,
			moveMeauDialog,
		},
		created()
		{
			this.getList();
		},
		methods:
		{
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			load() 
			{
				this.count+=6;
				this.listSix=this.list.slice(0,this.count);
			},
			
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 0,
				  moduleType: 'MODULE_PRODUCTIONS'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notData=false;
					}
					else
					{
						this.notData=true;
						this.list=response.data.data;
						this.listSix=this.list.slice(0,6)
					}
				})
			},
			todetails(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/moveNoticesDetails',
					query:
					{
						item:item,
						headTitle:'PRODUCTIONS',
						headImg:'/index/music.png',
					}
				})
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	@import '../../assets/MoveProduction.css';
	.productions{
		width: 96%;
		margin-left: 2%;
	}
	.productions-btn{
		width: 30%;
		height: 30px;
		line-height: 20px;
		border: none;
		border-radius: 50px;
		font-size: 15px;
		margin-top: 10px;
		background-color: #FCD002;
		color: #505050;
	}
	.error-img{
		width: 200px;
		height: 150px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
</style>